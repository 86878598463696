import { graphql } from 'gatsby';
import { Link } from 'gatsby';
import React from 'react';
import CtaButton from 'components/atoms/CtaButton';
import Layout from 'components/base/Layout';
import SEO from 'components/base/SEO';

const LoginPageContent = ({ main }) => (
  <div className="min-h-screen flex w-full">
    <div className="w-120 flex flex-col justify-center">
      <div className="p-14">
        <h1 className="text-4xl tracking-tightest font-semibold mb-3.5">
          {main?.title}
        </h1>
        <p className="mb-16 text-lg tracking-tighter">{main?.body}</p>

        <div>
          <label className="u-label" htmlFor="email">
            Email Address
          </label>
          <input className="u-input" type="email" id="email" name="email" />
        </div>

        <div>
          <label className="u-label" htmlFor="password">
            Password
          </label>
          <input
            className="u-input"
            type="password"
            id="password"
            name="password"
          />
        </div>

        <button>Log in</button>

        <div className="text-center">
          <Link className="u-cta-link" to="/forgotten-password">
            Forgot password?
          </Link>
        </div>
      </div>
    </div>
    <div
      className="bg-cover bg-center flex-grow"
      style={{ backgroundImage: 'url(/images/login-bg.png)' }}
    />
  </div>
);

export const LoginPagePreview = ({ entry }) => {
  return <LoginPageContent {...entry.get('data').toJS()} />;
};

const LoginPage = ({
  data: {
    markdownRemark: { frontmatter },
  },
}) => {
  return (
    <>
      <SEO title="Log in" />
      <LoginPageContent {...frontmatter} />
    </>
  );
};

export const pageQuery = graphql`
  query LoginPageQuery {
    markdownRemark(fileAbsolutePath: { regex: "/pages/log-in/" }) {
      frontmatter {
        main {
          title
          body
        }
      }
    }
  }
`;

export default LoginPage;
